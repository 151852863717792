<template>
    <div class="relative sales_statistics_panel">
        <dataTitle>
            <dateSearch @search="onSearchDetail"></dateSearch>
        </dataTitle>

        <dataTitle class="mt_20" slot="title" title="会员统计"></dataTitle>
        <div class="mt_10 relative flex flex_wrap align_center card_panel">
            <itemCard title="会员总数" :val="totalUser" unitAfter="人"></itemCard>
            <itemCard title="今日新增" :val="todayUser" unitAfter="人"></itemCard>
        </div>

        <div class="mt_20 relative width_100_percent flex flex_wrap align_start space_between detail_panel">
            <div class="relative detail_item">
                <dataTitle slot="title" title="新增人数"></dataTitle>
                <div class="relative bar_panel">
                    <bar className="sales_statistics_detail_bar" yName="单位：人" :chartData="personsDetail" :height="500"></bar>
                </div>
            </div>
            <div class="relative detail_item">
                <dataTitle slot="title" title="会员消费Top10"></dataTitle>
                <div class="relative width_100_percent table_panel">
                    <Table v-show="showUserRank" stripe class="width_100_percent mt_10" :columns="consumeRankColumn" :data="consumeRankList">
                        <template slot-scope="{ index }" slot="rankNo">
                            {{ index + 1 }}
                        </template>
                        <template slot-scope="{ row }" slot="message">
                            <div class="flex align_center" style="padding: 10px 0">
                                <previewImage :thumWidth="50" :thumHeight="50" :src="row.userImg"></previewImage>
                                <div class="ml_10">{{ row.nickname }}</div>
                            </div>
                        </template>
                        <template slot-scope="{ row }" slot="nums">
                            {{ row.nums | cny }}
                        </template>
                    </Table>
                </div>
            </div>
        </div>

        <div class="mt_20 relative width_100_percent flex flex_wrap align_start space_between detail_panel">
            <div class="relative detail_item">
                <dataTitle slot="title" title="用户分布图"></dataTitle>
                <div class="relative bar_panel">
                    <chinamap tooltipTitle="分布数量" className="china_map_data" :chartData="areaData" width="100%" :height="650"></chinamap>
                </div>
            </div>
            <div class="relative detail_item">
                <dataTitle slot="title" title="用户分布排行榜"></dataTitle>
                <div class="relative width_100_percent table_panel">
                    <Table stripe class="width_100_percent mt_10" :columns="areaRankColumn" :data="areaRankList"></Table>
                </div>
            </div>
        </div>

        <mySpin :loading="ajaxLoading"></mySpin>
    </div>
</template>
<script>
import dataTitle from './comp/dataTitle';
import dateSearch from './comp/dateSearch';
import bar from './comp/echarts/bar';
import itemCard from './comp/itemCard';
import chinamap from './comp/echarts/chinamap_effect';
import { reqUserStat } from '@/lib/request/auth2';
import { isFunction } from '@/lib/util';
import previewImage from '@/components/previewImage';
import { areaList } from '@/lib/city.data';

const moDef = {
    //totalUser-累计会员， todayUser-今日会员， addUser-新增会员明细， moneyUser-会员消费Top10  area-城市， areaTop-城市Top
    totalUser: 'totalUser',
    todayUser: 'todayUser',
    addUser: 'addUser',
    moneyUser: 'moneyUser',
    area: 'area',
    areaTop: 'areaTop',
};
const procince = areaList.province_list || {};

export default {
    name: 'vipStatistics',
    components: {
        dataTitle,
        dateSearch,
        bar,
        itemCard,
        chinamap,
        previewImage,
    },
    data() {
        return {
            detail: {},
            totalUser: 0,
            todayUser: 0,
            personsDetail: [],
            consumeRankList: [],
            consumeRankColumn: [
                { slot: 'rankNo', title: '排名', width: 70 },
                { slot: 'message', title: '用户名称' },
                { slot: 'nums', title: '累计消费', width: 150 },
            ],
            areaRankList: [],
            showUserRank: false,
            areaRankColumn: [
                { key: 'name', title: '地区' },
                { key: 'nums', title: '人数' },
            ],
            areaData: [],
        };
    },
    mounted() {
        this.getDetail();
    },
    watch: {
        //
    },
    methods: {
        //获取详情
        getDetail() {
            this.reqData({ mo: moDef.todayUser }, data => {
                this.todayUser = data.row || 0;
            });
        },
        reqData(params, cb) {
            this.showAjaxLoading();
            reqUserStat(params)
                .then(res => {
                    let data = res?.data || {};
                    isFunction(cb) && cb(data);
                })
                .catch(() => {
                    isFunction(cb) && cb({});
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },

        //查询销售明细
        onSearchDetail(arrDate) {
            this.showAjaxLoading();
            let timeDate = '';
            if (!!arrDate[0] && !!arrDate[1]) {
                timeDate = JSON.stringify([arrDate[0], arrDate[1]]);
            }

            let dataTotalUser = {
                mo: moDef.totalUser,
                timeDate,
            };
            this.reqData(dataTotalUser, data => {
                this.totalUser = data.row || 0;
                console.log('会员总数 req', dataTotalUser, 'rsp', data);
            });

            this.reqData({ mo: moDef.addUser, timeDate }, data => {
                let personsDetail = data.list || [];
                personsDetail.forEach(info => {
                    info.name = info.ctime || '';
                    info.value = info.nums || 0;
                });
                this.personsDetail = personsDetail;
            });

            this.showUserRank = false;
            this.reqData({ mo: moDef.moneyUser, timeDate }, data => {
                this.consumeRankList = data?.goodList || [];
                this.showUserRank = true;
            });

            this.reqData({ mo: moDef.area, timeDate }, data => {
                console.log('城市', data);
                let area = data.area || [];
                area.forEach(info => {
                    info.name = procince[info.sheng] || info.sheng;
                    info.value = info.nums || 0;
                });
                this.areaData = area;
            });
            //用户分布TOP10
            this.reqData({ mo: moDef.areaTop, timeDate }, data => {
                console.log('城市TOP10', data);
                let areaRankList = data?.areaTop || [];
                areaRankList.forEach(info => {
                    info.name = procince[info.sheng] || info.sheng;
                });
                this.areaRankList = areaRankList;
            });
        },
    },
};
</script>

<style lang="less" scoped>
.sales_statistics_panel {
    .card_panel {
        gap: 15px;
        .card {
            flex: 1;
        }
    }

    .money_panel {
        width: 48%;
        min-width: 500px;
    }
    .detail_panel {
        gap: 20px;
        .detail_item {
            width: 48%;
            min-width: 500px;
        }
    }
}
</style>
