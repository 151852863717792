<template>
    <div>
        <div class="chinamap_effect" :ref="className" :style="{ width: (this.width || 400) + 'px', height: (this.height || 400) + 'px' }"></div>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import theme from './roma';
import { provincePos } from './pos';
import './china';

echarts.registerTheme('roma', theme.roma);

export default {
    name: 'bar',
    props: ['width', 'height', 'chartData', 'className', 'tooltipTitle'],
    data() {
        return {
            pie: [],
            myChart: null,
        };
    },
    mounted() {
        this.drawChart();
    },
    methods: {
        resize() {
            this.myChart && this.myChart.resize();
        },
        drawChart() {
            this.myChart = echarts.init(this.$refs[this.className], 'roma');
            window.onresize = () => {
                this.resize();
            };
            const resizeObserver = new ResizeObserver(entries => {
                this.resize();
            });
            resizeObserver.observe(this.$parent.$el);

            let data = structuredClone(this.chartData);
            let dataObj = {};
            data.sort((item1, item2) => item2.value - item1.value);
            data.forEach((info, idx) => {
                let val = info.value;
                let name = info.name;
                name = name.split('省')[0];
                if (provincePos[name]) {
                    data[idx].value = provincePos[name].concat(val);
                    dataObj[name] = val;
                }
            });
            let symbolSizes = [25, 20, 15, 8];
            let symbolColors = ['rgba(0,0,255, 0.55)', 'rgba(65,105,225, 0.75)', 'rgba(100,149,237, 0.75)', 'rgba(70,130,180, 0.75)', 'rgba(135,206,235, 0.75)'];
            // 指定图表的配置项和数据
            let tooltipTitle = this.tooltipTitle || '数量';
            const option = {
                tooltip: {
                    trigger: 'item',
                },
                toolbox: {
                    show: true,
                    feature: {
                        saveAsImage: { show: true },
                    },
                },
                geo: {
                    type: 'map',
                    map: 'china',
                    roam: true, //scale、move、true(包括scale和move)
                    scaleLimit: {
                        min: 0.2,
                        max: 2,
                    },
                    label: {
                        show: false,
                    },
                    itemStyle: {
                        areaColor: 'rgba(245, 246, 250)', //地图区域的颜色。
                        borderColor: '#835f5f', //图形的描边颜色
                    },
                    emphasis: {
                        //高亮状态下
                        itemStyle: {
                            areaColor: 'rgba(250, 124, 4, 0.85)',
                        },
                        label: {
                            show: false,
                        },
                    },
                    tooltip: {
                        show: true,
                        formatter(params) {
                            // console.log('geo tooltip params', params)
                            let name = params.name;
                            return `${name}<br>${tooltipTitle}：${dataObj[name] || 0}`;
                        },
                    },
                },
                series: [
                    {
                        name: tooltipTitle,
                        type: 'effectScatter',
                        coordinateSystem: 'geo',
                        // 用来指定value数组中的第三个数字才是value值
                        encode: {
                            value: 2,
                        },
                        //设置圆圈大小
                        symbolSize: function (val, params) {
                            let idx = params.dataIndex;
                            idx >= symbolSizes.length && (idx = symbolSizes.length - 1);
                            return symbolSizes[idx];
                        },
                        showEffectOn: 'render',
                        rippleEffect: {
                            brushType: 'stroke', //stroke、fill
                        },
                        emphasis: {
                            scale: false,
                        },
                        label: {
                            formatter: '{b}',
                            position: 'right',
                            show: true,
                            color: 'rgba(76, 86, 192)',
                            fontSize: 11,
                            textBorderWidth: 2,
                            textBorderColor: '#fff',
                        },
                        itemStyle: {
                            shadowBlur: 10,
                            shadowColor: function (params) {
                                let idx = params.dataIndex;
                                idx >= symbolColors.length && (idx = symbolColors.length - 1);
                                return symbolColors[idx];
                            },
                            color: function (params) {
                                let idx = params.dataIndex;
                                idx >= symbolColors.length && (idx = symbolColors.length - 1);
                                return symbolColors[idx];
                            },
                        },
                        zlevel: 1,
                        data: data,
                    },
                ],
            };
            this.myChart.setOption(option);
        },
    },
    watch: {
        chartData: {
            deep: true,
            handler() {
                // console.log('watch chartData...');
                this.drawChart();
            },
        },
    },
};
</script>

<style scoped lang="less">
.chinamap_effect {
    margin: 0 auto;
}
</style>
